// TODO PCS refactor ternaries
/* eslint-disable no-nested-ternary */
import { useTranslation } from '@gemlaunch/localization'
import { ChainId } from '@gemlaunch/sdk'
import truncateHash from '@gemlaunch/utils/truncateHash'
import { ArrowBackIcon, ArrowForwardIcon, Box, Flex, LinkExternal, Radio, Skeleton, Text } from '@gemlaunch/uikit'
import { ITEMS_PER_INFO_TABLE_PAGE } from 'config/constants/info'
import { formatDistanceToNowStrict } from 'date-fns'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useGetChainName } from 'state/info/hooks'
import { Transaction, TransactionType } from 'state/info/types'
import styled from 'styled-components'
import { getBlockExploreLink } from 'utils'

import { formatAmount } from 'utils/formatInfoNumbers'
import { Arrow, Break, ClickableColumnHeader, PageButtons, TableWrapper } from './shared'
import { useSidNameForAddress } from '../../../../hooks/useSid'

const Wrapper = styled.div`
  width: auto;
  height: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  background-color: #236457;
  padding: 10px 10px;
  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr repeat(4, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1.5fr repeat(2, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1.5fr 1fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
  }
`

interface IResponsiveGridItems {
  even: boolean
}
const ResponsiveGridItems = styled.div<IResponsiveGridItems>`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  background-color: ${(props) => (props.even ? '#23645766' : '#314D47B2')};
  padding: 0 24px;
  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr repeat(4, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1.5fr repeat(2, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1.5fr 1fr;
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
  }
`

const RadioGroup = styled(Flex)`
  align-items: center;
  /* margin-right: 16px; */
  /* margin-top: 8px; */
  cursor: pointer;
`

const SORT_FIELD = {
  amountUSD: 'amountUSD',
  timestamp: 'timestamp',
  sender: 'sender',
  amountToken0: 'amountToken0',
  amountToken1: 'amountToken1',
}

const TableLoader: React.FC<React.PropsWithChildren> = () => {
  const loadingRow = (
    <ResponsiveGrid>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </ResponsiveGrid>
  )
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  )
}

const DataRow: React.FC<React.PropsWithChildren<{ transaction: Transaction; even: boolean }>> = ({
  transaction,
  even,
}) => {
  const { t } = useTranslation()
  const abs0 = Math.abs(transaction.amountToken0)
  const abs1 = Math.abs(transaction.amountToken1)
  const outputTokenSymbol = transaction.amountToken0 < 0 ? transaction.token0Symbol : transaction.token1Symbol
  const inputTokenSymbol = transaction.amountToken1 < 0 ? transaction.token0Symbol : transaction.token1Symbol
  const chainName = useGetChainName()
  const { sidName } = useSidNameForAddress(transaction.sender)

  const title = `${transaction.type === TransactionType.MINT
      ? t('Add %token0% and %token1%', { token0: transaction.token0Symbol, token1: transaction.token1Symbol })
      : transaction.type === TransactionType.SWAP
        ? t('Swap %token0% for %token1%', { token0: inputTokenSymbol, token1: outputTokenSymbol })
        : t('Remove %token0% and %token1%', { token0: transaction.token0Symbol, token1: transaction.token1Symbol })
    }`
  return (
    <ResponsiveGridItems even={even} style={{ padding: '1rem', color: 'white' }}>
      <LinkExternal
        isBscScan
        href={getBlockExploreLink(transaction.hash, 'transaction', chainName === 'ETH' && ChainId.ETHEREUM)}
      >
        <Text
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '150px', color: 'white' }}
          title={title}
        >
          {title}
        </Text>
      </LinkExternal>
      <LinkExternal
        isBscScan
        href={getBlockExploreLink(transaction.sender, 'address', chainName === 'ETH' && ChainId.ETHEREUM)}
      >
        {sidName || truncateHash(transaction.sender)}
      </LinkExternal>
      <Text
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100px', color: 'white' }}
      >
        ${formatAmount(transaction.amountUSD)}
      </Text>
      <Text>{formatDistanceToNowStrict(parseInt(transaction.timestamp, 10) * 1000)}</Text>
    </ResponsiveGridItems>
  )
}

const TransactionTable: React.FC<
  React.PropsWithChildren<{
    transactions: Transaction[]
  }>
> = ({ transactions }) => {
  const [sortField, setSortField] = useState(SORT_FIELD.timestamp)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  const [txFilter, setTxFilter] = useState<TransactionType | undefined>(undefined)

  const sortedTransactions = useMemo(() => {
    const toBeAbsList = [SORT_FIELD.amountToken0, SORT_FIELD.amountToken1]
    return transactions
      ? transactions
        .slice()
        .sort((a, b) => {
          if (a && b) {
            const firstField = a[sortField as keyof Transaction]
            const secondField = b[sortField as keyof Transaction]
            const [first, second] = toBeAbsList.includes(sortField)
              ? [Math.abs(firstField as number), Math.abs(secondField as number)]
              : [firstField, secondField]
            return first > second ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return -1
        })
        .filter((x) => {
          return txFilter === undefined || x.type === txFilter
        })
        .slice(ITEMS_PER_INFO_TABLE_PAGE * (page - 1), page * ITEMS_PER_INFO_TABLE_PAGE)
      : []
  }, [transactions, page, sortField, sortDirection, txFilter])

  // Update maxPage based on amount of items & applied filtering
  useEffect(() => {
    if (transactions) {
      const filteredTransactions = transactions.filter((tx) => {
        return txFilter === undefined || tx.type === txFilter
      })
      if (filteredTransactions.length % ITEMS_PER_INFO_TABLE_PAGE === 0) {
        setMaxPage(Math.floor(filteredTransactions.length / ITEMS_PER_INFO_TABLE_PAGE))
      } else {
        setMaxPage(Math.floor(filteredTransactions.length / ITEMS_PER_INFO_TABLE_PAGE) + 1)
      }
    }
  }, [transactions, txFilter])

  const handleFilter = useCallback(
    (newFilter: TransactionType) => {
      if (newFilter !== txFilter) {
        setTxFilter(newFilter)
        setPage(1)
      }
    },
    [txFilter],
  )

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      const directionArrow = !sortDirection ? '↑' : '↓'
      return sortField === field ? directionArrow : ''
    },
    [sortDirection, sortField],
  )

  const getFilterStyle = (filterType: TransactionType) => ({
    color: txFilter === filterType ? 'white' : 'gray',
    fontWeight: txFilter === filterType ? 'bold' : 'normal',
  })

  return (
    // <Wrapper>
    <TableWrapper>
      <ResponsiveGrid>
        {/* <Text color="secondary" fontSize="12px" bold textTransform="uppercase">
          <Flex flexDirection={['column', 'row']}>
            <RadioGroup onClick={() => handleFilter(undefined)}>
              <Text ml="8px" fontSize="12px" style={getFilterStyle(undefined)}>{t('All')}</Text>
            </RadioGroup>

            <RadioGroup onClick={() => handleFilter(TransactionType.SWAP)}>
              <Text ml="8px" fontSize="12px" style={getFilterStyle(TransactionType.SWAP)} >{t('Swaps')}</Text>
            </RadioGroup>
            <RadioGroup onClick={() => handleFilter(TransactionType.MINT)}>
              <Text ml="8px" fontSize="12px" style={getFilterStyle(TransactionType.MINT)} >{t('Adds')}</Text>
            </RadioGroup>

            <RadioGroup onClick={() => handleFilter(TransactionType.BURN)}>
              <Text ml="8px" fontSize="12px" style={getFilterStyle(TransactionType.BURN)} >{t('Removes')}</Text>
            </RadioGroup>
          </Flex>
        </Text> */}
        <ClickableColumnHeader
          color="white"
          fontSize="16px"
          bold
          onClick={() => handleSort(SORT_FIELD.timestamp)}
          textTransform="uppercase"
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('Action')} {arrow(SORT_FIELD.timestamp)}
        </ClickableColumnHeader>
        <ClickableColumnHeader
          color="white"
          fontSize="16px"
          bold
          onClick={() => handleSort(SORT_FIELD.sender)}
          textTransform="uppercase"
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('TXN')} {arrow(SORT_FIELD.sender)}
        </ClickableColumnHeader>
        <ClickableColumnHeader
          color="white"
          fontSize="16px"
          bold
          onClick={() => handleSort(SORT_FIELD.amountUSD)}
          textTransform="uppercase"
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('Value')} {arrow(SORT_FIELD.amountUSD)}
        </ClickableColumnHeader>
        <ClickableColumnHeader
          color="white"
          fontSize="16px"
          bold
          onClick={() => handleSort(SORT_FIELD.timestamp)}
          textTransform="uppercase"
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('Time')} {arrow(SORT_FIELD.timestamp)}
        </ClickableColumnHeader>
      </ResponsiveGrid>
      <Break />

      {transactions ? (
        <>
          {sortedTransactions.map((transaction, index) => {
            if (transaction) {
              const isEven = index % 2 === 0
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  <DataRow transaction={transaction} even={isEven} />
                  {/* <Break /> */}
                </Fragment>
              )
            }
            return null
          })}
          {sortedTransactions.length === 0 ? (
            <Flex justifyContent="center">
              <Text>{t('No Transactions')}</Text>
            </Flex>
          ) : undefined}
          <PageButtons>
            <Arrow
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
            </Arrow>

            <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>
            <Arrow
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
            </Arrow>
          </PageButtons>
        </>
      ) : (
        <>
          <TableLoader />
          <Box />
        </>
      )}
    </TableWrapper>
    // </Wrapper>
  )
}

export default TransactionTable
