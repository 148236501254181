



import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5Z" fill="#090909" />
      <path d="M8.75 20.4167H26.25V23.3333H17.5V27.7083L8.75 20.4167ZM26.25 16.0417H8.75V13.125H17.5V8.75L26.25 16.0417Z" fill="#22CDA6" />
    </Svg>
  );
};

export default Icon;
