import { useTranslation } from '@gemlaunch/localization'
import { Currency } from '@gemlaunch/sdk'
import { BottomDrawer, Box, Flex, Grid, Heading, Modal, ModalV2, useMatchBreakpoints } from '@gemlaunch/uikit'
import replaceBrowserHistory from '@gemlaunch/utils/replaceBrowserHistory'
import { AppBody } from 'components/App'
import { useCallback, useContext } from 'react'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { currencyId } from 'utils/currencyId'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useSwapHotTokenDisplay } from 'hooks/useSwapHotTokenDisplay'
import TransactionTable from 'views/Info/components/InfoTables/TransactionsTable'
import { useProtocolTransactionsSWR } from 'state/info/hooks'
import { useCurrency } from '../../hooks/Tokens'
import { Field } from '../../state/swap/actions'
import { useSingleTokenSwapInfo, useSwapState } from '../../state/swap/hooks'
import Page from '../Page'
import PriceChartContainer from './components/Chart/PriceChartContainer'
import HotTokenList from './components/HotTokenList'
import useWarningImport from './hooks/useWarningImport'
import { SmartSwapForm } from './SmartSwap'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import { SwapFeaturesContext } from './SwapFeaturesContext'

const queryClient = new QueryClient()

export default function Swap() {
  const { isDesktop } = useMatchBreakpoints()
  const transactions = useProtocolTransactionsSWR()

  const { isChartExpanded, isChartDisplayed, setIsChartDisplayed, setIsChartExpanded, isChartSupported } =
    useContext(SwapFeaturesContext)
  const [isSwapHotTokenDisplay, setIsSwapHotTokenDisplay] = useSwapHotTokenDisplay()
  const { t } = useTranslation()

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)
  const warningSwapHandler = useWarningImport()
  const { onCurrencySelection } = useSwapActionHandlers()

  const handleOutputSelect = useCallback(
    (newCurrencyOutput: Currency) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput)
      warningSwapHandler(newCurrencyOutput)

      const newCurrencyOutputId = currencyId(newCurrencyOutput)
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory('inputCurrency', outputCurrencyId)
      }
      replaceBrowserHistory('outputCurrency', newCurrencyOutputId)
    },

    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  return (
    <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
      <Flex
        width={['100%']}
        height="100%"
        justifyContent="center"
        position="relative"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Grid gridTemplateColumns={['100%', '100', '100%', '100% 100%']} style={{ gap: '20px 0' }} mt="5.2rem">
          <StyledSwapContainer $isChartExpanded={isChartExpanded}>
            <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
              <AppBody>
                <QueryClientProvider client={queryClient}>
                  <SmartSwapForm handleOutputSelect={handleOutputSelect} />
                </QueryClientProvider>
              </AppBody>
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
        </Grid>
        <Grid gridTemplateColumns={['100%', '100', '100%', '100% 100%']} style={{ gap: '20px 0' }} mt="5.2rem">
          <Box
            style={{
              overflowX: 'auto',
              borderRadius: '20px',
            }}
          >
            <TransactionTable transactions={transactions} />
          </Box>
        </Grid>
      </Flex>
    </Page>
  )
}
