import { Box, Flex } from '@gemlaunch/uikit'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 16px; */
  width: auto;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 40px 0 0;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 0 120px' : 'padding: 0 40px')};
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 100%;
  max-width: 500px;
`

export const StyledAdvanceTradeDetails = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.disabled};
`
